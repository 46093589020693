<template>
  <div id="main-content" class="main-content">
      <Refresh show_unbind="1" />
    <div class="content-list">
      <div class="memeberRecord_index">
        <div class="myMember_source_tab">
          <div style="width: 80px; text-align: center;" :class="navTab==0?'tabActive':''" data-index='0' @click="handleTab(0)">基本信息</div>
          <div style="width: 80px; text-align: center;" :class="navTab==1?'tabActive':''" data-index='1' @click="handleTab(1)">报名记录</div>
          <div style="width: 80px; text-align: center;" :class="navTab==2?'tabActive':''" data-index='2' @click="handleTab(2)">在离职记录</div>
          <div style="width: 80px; text-align: center;" :class="navTab==3?'tabActive':''" data-index='3' @click="handleTab(3)">打卡记录</div>
        </div>
        <div class="myMember_source_tab">
          <div style="width: 80px; text-align: center;" :class="navTab==4?'tabActive':''" data-index='4' @click="handleTab(4)">回访记录</div>
          <div style="width: 80px; text-align: center;" :class="navTab==5?'tabActive':''" data-index='5' @click="handleTab(5)">发薪记录</div>
<!--          <div style="width: 80px; text-align: center;" :class="navTab==10?'tabActive':''" data-index='10' @click="handleTab(10)">工资条</div>-->
          <div style="width: 80px; text-align: center;" :class="navTab==6?'tabActive':''" data-index='6' @click="handleTab(6)">银行卡信息</div>
          <div style="width: 80px; text-align: center;" :class="navTab==7?'tabActive':''" data-index='7' @click="handleTab(7)">异常记录</div>
        </div>
        <div class="myMember_source_tab">

          <div style="width: 80px; text-align: center;" :class="navTab==8?'tabActive':''" data-index='8' @click="handleTab(8)">住宿记录</div>
<!--          <div style="width: 80px; text-align: center;" :class="navTab==9?'tabActive':''" data-index='9' @click="handleTab(9)">订单查看记录</div>-->
          <div style="width: 80px; text-align: center;" :class="navTab==11?'tabActive':''" data-index='11' @click="handleTab(11)">借支离职工资</div>
        </div>
      </div>
      <div class="navTab_one" v-if='navTab==0'>
        <div class="navTab_one_list">
          <div>姓名：</div>
          <div><text class="name_icon" v-if='info.store_type'>企</text>{{info.real_name}}</div>
        </div>
        <div class="navTab_one_list">
          <div>年龄：</div>
          <div>{{info.age}}</div>
        </div>
        <div class="navTab_one_list">
          <div>性别：</div>
          <div>{{info.sex}}</div>
        </div>
        <div class="navTab_one_list">
          <div>身份证：</div>
          <div>{{info.id_number}}</div>
        </div>
        <div class="navTab_one_list">
          <div>电话：</div>
          <div>{{info.phone}}</div>
        </div>
        <div class="navTab_one_list">
          <div>户籍地址：</div>
          <div>{{info.domicile}}</div>
        </div>
        <div class="navTab_one_list">
          <div>推荐人：</div>
          <div>{{info.recommender}}</div>
        </div>
        <div class="navTab_one_list">
          <div>来源：</div>
          <div>{{info.source}}</div>
        </div>
      </div>

      <div class="navTab_two" v-if='navTab==1'>
        <div class="navTab_two_tab">
          <div style="width: 14%;">企业简称</div>
          <div style="width: 17%;">职位</div>
          <div style="width: 12%;">来源</div>
          <div style="width: 12%;">报名状态</div>
          <div style="width: 12%;">发单情况</div>
          <div style="width: 12%;">详情</div>
          <div style="width: auto">报名日期</div>
        </div>
        <div class="navTab_two_list" v-for="(item, index) in signList" :key='index'>
          <div style="width: 14%;">{{item.abbreviation}}</div>
          <div style="width: 17%;">{{item.job_title}}</div>
          <div style="width: 12%;">{{item.source}}</div>
          <div style="width: 12%;">{{item.audit_status}}</div>
          <div style="width: 12%;color:#1F97EE" @click="handleSee(item.wages_describe)" data-content='item.wages_describe'>查看</div>
          <div style="width: 12%;color:#1F97EE" @click="handleDetail(item.id)" data-applets_id='item.id'>查看</div>
<!--          <div style="width: 22%;">{{m1.toJson(item.created_at,0,10)}}</div>-->
          <div style="width: auto">{{item.created_at}}</div>
        </div>
      </div>

      <div class="navTab_two" v-if='navTab==2'>
        <div class="navTab_two_tab">
          <div style="width: 14%;">企业简称</div>
          <div style="width: 17%;">职位</div>
          <div style="width: 12%;">来源</div>
          <div style="width: 12%;">报名状态</div>
          <div style="width: 12%;">发单情况</div>
          <div style="width: 12%;">详情</div>
          <div style="width: auto">报名日期</div>
        </div>
        <div class="navTab_two_list" v-for="(item, index) in jobList" :key='index'>
          <div style="width: 14%;">{{item.abbreviation}}</div>
          <div style="width: 17%;">{{item.job_title}}</div>
          <div style="width: 12%;">{{item.source}}</div>
          <div style="width: 12%;">{{item.audit_status}}</div>
          <div style="width: 12%;color:#1F97EE" @click="handleSee(item.wages_describe)" data-content='item.wages_describe'>查看</div>
          <div style="width: 12%;color:#1F97EE" @click="handleDetail(item.id)" data-applets_id='item.id'>查看</div>
          <!--          <div style="width: 22%;">{{m1.toJson(item.created_at,0,10)}}</div>-->
          <div style="width: auto">{{item.created_at}}</div>
        </div>
      </div>

      <div class="navTab_four" v-if='navTab==3'>
        <div class="navTab_four_tab">
          <div>企业简称</div>
          <div style="width: 12%;">班别</div>
          <div style="width: 12%;">打卡</div>
          <div>上班时间</div>
          <div>下班时间</div>
          <div>上下班地址</div>
        </div>
        <div class="navTab_four_list" v-for="(item, index) in clockList" :key='index'>
          <div>{{item.abbreviation}}</div>
          <div style="width: 12%;" :class="item.classing=='夜班'?'navTab_four_list_day navTab_four_list_day_night':'navTab_four_list_day'"><span>{{item.classing}}</span></div>
          <div style="width: 12%;" :class="item.is_unusual=='异常'?'navTab_four_list_status navTab_four_list_status_error':'navTab_four_list_status'"><span>{{item.is_unusual}}</span></div>
          <div>{{item.on_work_time}}<span class="empty_cell" v-if="!item.on_work_time">0000-00-00 00:00:00</span></div>
          <div>{{item.off_work_time}}<span class="empty_cell" v-if="!item.off_work_time">0000-00-00 00:00:00</span></div>
          <div style="color:#1F97EE" @click="handleSee(item)">查看</div>
        </div>
      </div>


      <div class="navTab_five" v-if='navTab==4'>
        <div class="navTab_five_tab">
          <div :class="historyIndex==1?'historyActive':''" @click="handleHistory(1)">回访记录</div>
          <div :class="historyIndex==2?'historyActive':''" @click="handleHistory(2)">历史回访</div>
        </div>
        <div class="public_visit_flex">
          <div>总条数：{{visitNum}}</div>
<!--          <div v-if="historyIndex==1" class="navTab_five_tab_choose" @click="handleChoose">无效</div>-->
<!--          <div v-if="historyIndex==1" class="public_visit_div" @click="handleVisit">回访</div>-->
        </div>
        <div v-if="historyIndex==1">
          <div class="public_index_main" v-for="(item, index) in visitList" :key='index'>
            <div class="public_index_main_list">
              <div>跟进记录：</div>
              <div>{{item.msg}}</div>
            </div>
            <div class="public_index_main_list_time">
              <div>跟进时间：</div>
              <div>{{item.updated_at}}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="public_index_main" v-for="(item, index) in visitList" :key='index'>
            <div class="public_index_main_list">
              <div>回访人：</div>
              <div>{{item.name}}</div>
            </div>
            <div class="public_index_main_list">
              <div>跟进记录：</div>
              <div>{{item.msg}}</div>
            </div>
            <div class="public_index_main_list_time">
              <div>跟进时间：</div>
              <div>{{item.updated_at}}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="navTab_six" v-if='navTab==5'>
        <div class="navTab_six_list" v-for="(item, index) in moneyList" :key='index'>
          <!-- <div class="navTab_six_list_flex">
            <div>ID：</div>
            <div>{{item.id}}</div>
          </div> -->
          <div class="navTab_six_list_flex">
            <div>发薪信息：</div>
            <div class="navTab_six_list_flex_title">
              <div class="navTab_six_list_flex_son">
                <div>发薪单位：</div>
                <div>{{item.companyName}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>付款账户账号：</div>
                <div>{{item.accountNo}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>付款账户开户行：</div>
                <div>{{item.channelTypeStr}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>工资单类型：</div>
                <div>{{item.payrollTypeStr}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>发薪时间：</div>
                <div>{{item.payDayStr}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>银行摘要：</div>
                <div>{{item.remark}}</div>
              </div>
              <!-- <div class="navTab_six_list_flex_son">
                <div>项目名称：</div>
                <div>{{item.ruleName}}</div>
              </div> -->
            </div>
          </div>
          <div class="navTab_six_list_flex">
            <div>领薪信息：</div>
            <div class="navTab_six_list_flex_title">
              <div class="navTab_six_list_flex_son">
                <div>姓名：</div>
                <div>{{item.customerName}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>身份证：</div>
                <div>{{item.idCard}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>用人单位：</div>
                <div>{{item.employmentName}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>开户行：</div>
                <div>{{item.bankAccount}}</div>
              </div>
              <div class="navTab_six_list_flex_son">
                <div>收款账户：</div>
                <div>{{item.bankNumber}}</div>
              </div>
            </div>
          </div>
          <div class="navTab_six_list_flex">
            <div>提现状态：</div>
            <div>{{item.payOffStr}}</div>
          </div>
          <div class="navTab_six_list_flex">
            <div>金额(元)：</div>
            <div>{{item.realWage}}</div>
          </div>
          <div class="navTab_six_list_flex">
            <div>发薪类型：</div>
            <div v-if="item.type==0">灵活发薪</div>
            <div v-else>一键发薪</div>
          </div>
        </div>
      </div>

      <div class="navTab_seven" v-if='navTab==6'>
        <div class="navTab_seven_list" v-for="(item, index) in bankList" :key='index'>
          <div class="navTab_seven_list_flex">
            <div>银行名称： </div>
            <div>{{item.bank_name}}</div>
          </div>
          <div class="navTab_seven_list_flex">
            <div>银行卡号：</div>
            <div>{{item.bank_number}}</div>
          </div>
          <div class="navTab_seven_list_flex">
            <div>银行绑定手机号：</div>
            <div>{{item.phone}}</div>
          </div>
          <div class="navTab_seven_list_flex">
            <div>银行卡选择：</div>
            <div>{{item.selected}}</div>
          </div>
          <div class="navTab_seven_list_flex">
            <div>是否作废：</div>
            <div>{{item.abandon}}</div>
          </div>
        </div>
      </div>

      <div class="navTab_eight" v-if='navTab==7'>
        <div class="navTab_eight_flex">
          <div>总条数：{{payNum}}条</div>
<!--          <div class="navTab_eight_div" @click="handleFeed">-->
<!--            <van-icon color='#1F97EE' size='26rpx' name="plus" />提交反馈-->
<!--          </div>-->
        </div>
        <div class="public_index_main" v-for="(item, index) in payList" :key='index'>
          <div class="public_index_main_list">
            <div>问题类型：</div>
            <div class="navTab_eight_title">
              {{item.type}}
              <div :class="item.state=='已处理'?'navTab_eight_status navTab_eight_status_processed' : 'navTab_eight_status'">{{item.state}}</div>
            </div>
          </div>
          <div class="public_index_main_list">
            <div>问题反馈：</div>
            <div class="navTab_eight_over">{{item.describe}}</div>
          </div>
          <div class="public_index_main_list_time">
            <div>反馈时间：</div>
            <div>{{item.created_at}}</div>
          </div>
        </div>
      </div>

      <div class="navTab_nine" v-if='navTab==8'>
        <div class="navTab_nine_tab">
          <div style="width: 14%;">企业名称</div>
          <div style="width: 12%;">房间</div>
          <div style="width: 15%;">床位</div>
          <div style="width: 16%;">状态</div>
          <div>入住时间</div>
          <div>更新时间</div>
        </div>
        <div class="navTab_nine_list" v-for="(item, index) in stayList" :key='index'>
          <div style="width: 10%;">{{item.dormitory_name}}</div>
          <div style="width: 18%;">{{item.room_name}}</div>
          <div style="width: 15%;">{{item.bed_name}}</div>
          <div style="width: 10%;">{{item.dormitory_status}}</div>
          <div style="width: 22%;">{{item.created_at}}</div>
          <div style="width: 25%;">{{item.updated_at}}</div>
        </div>
      </div>

      <div class="navTab_ten" v-if='navTab==9'>
        <div class="navTab_ten_time">
          <div class="data_week_btn_time">{{timeBean.yearMonth}}年</div>
<!--          <weekCalendar style="width:100%" timeBean="{{timeBean}}" bind:lastWeek="lastWeek" bind:nextWeek="nextWeek" bind:dayClick="dayClick">-->
<!--          </weekCalendar>-->
        </div>
        <div class="navTab_ten_main">
          <div class="navTab_ten_tab">
            <div style="width: 50%;">订单名称</div>
            <div style="width: 30%;">订单日期</div>
            <div style="width: 30%;">点击查看次数(次)</div>
          </div>
          <div class="navTab_ten_list" v-for="(item, index) in orderList" :key="index">
            <div style="width: 50%;">{{item.job_title}}</div>
            <div style="width: 30%;">{{item.recruitment_date}}</div>
            <div style="width: 30%;text-align: center;">{{item.click_count}}</div>
          </div>
        </div>
      </div>

      <div class="navTab_eleven" v-if='navTab==10'>
        <div v-if="arrlist.length!=0">
          <!-- <div class="paySilp">
            <div v-if="arrlist.length!=0" class="paySilp_name">{{res.name}}，工作辛苦了</div>
            <div v-else class="paySilp_name">工资条暂未批放，请耐心等待</div>
          </div> -->
<!--          <picker mode="selector" value="{{index}}" range="{{arrDate}}" bindchange='bindPickerChange' range-key="{{'time'}}">-->
<!--            <div class="picker">-->
<!--              工资月份：{{arrDate[index].time}}-->
<!--              <van-icon custom-class='picker_play' name="play" />-->
<!--            </div>-->
<!--          </picker>-->
          <van-action-sheet v-model="yearMonthPicker" title="标题">
            <div class="content">

              <van-picker
                  title="标题"
                  show-toolbar
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="onCancel"
                  @change="onChange"
              />

            </div>
          </van-action-sheet>
          <div class="paySilp_day">
            <div class="paySilp_day_title">详情列表</div>
            <div>
              <div class="paySilp_day_list" v-for="(item, index) in arrlist" :key='index' v-if="item.text!=null&&item.text!=''">
                <div>{{item.name}}</div>
                <div>{{item.text}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navTab_nine" v-if='navTab==11'>
        <div class="navTab_nine_tab">
          <div style="width: 14%;">姓名</div>
          <div style="width: 14%;">企业</div>
          <div style="width: 18%;">金额</div>
          <div style="width: 12%;">来源</div>
          <div>备注</div>
          <div>创建时间</div>
        </div>
        <div class="navTab_nine_list" v-for="(item, index) in quitList" :key='index'>
          <div style="width: 12%;">{{item.name}}</div>
          <div style="width: 18%;">{{item.enterprise.abbreviation}}</div>
          <div style="width: 15%;">{{item.advance_amount}}</div>
          <div style="width: 18%;">{{item.source}}</div>
          <div style="color:#1F97EE;width: 12%;" @click="handleSee(item.remarks)">查看</div>
          <div style="width: 25%;">{{item.created_at}}</div>
        </div>
      </div>

      <div class="moreNull">暂无更多数据~</div>
<!--      <div class="memeberPhone" @click="handlePhone">-->
<!--        <image src="/img/memeberPhone.png"></image>-->
<!--      </div>-->

    </div>

    <van-popup class="showContent" v-model="showContent" closeable>

      <div class="showContent_signItem" v-if='navTab==1||navTab==2||navTab==11' v-html="signItem"></div>

      <div class="showContent_time" v-if='navTab==3'>
        <div>上班地址：<span>{{itemclock.on_work_address}}</span></div>
        <div>下班地址：<span>{{itemclock.off_work_address}}</span></div>
      </div>
    </van-popup>

    <van-popup v-model="showPut" close="onClose">
      <div class="showPut">
        <div class="showPut_no" v-if='isChoose==2'>
          <div class="showPut_no_title">无效会员</div>
          <div class="showPut_no_tip">
            <van-icon size='16px' name="warning-o" />
            <div>选择此标签后，该会员变为无效会员，不在退回公共会员池！</div>
          </div>
        </div>
        <div class="showPut_title">
          <div :class="navPut==index?'showPut_title_active':''" @click="handleSPut(index, item)" v-for="(item, index) in (isChoose==2?visitNoTip:visitTip)" :key="index">{{item}}</div>
        </div>
        <div class="showPut_content">
          <textarea v-if='isChoose==1' :value="val" placeholder="请输入"></textarea>
        </div>
        <div class="showPut_button">
          <div @click="onClose">取消</div>
          <div @click="handlePSubmit">确定</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>
<script>

import {
  getKehuDetail,
  setVisit,
  getYearMonth,
  checkClearCacheDataForce
} from '@/api/test'
import {checkBind, checkLoadWxConfig, clearCacheData, getUserInfo, setEnterPath} from "../../utils/base";
import Refresh from "@/components/home/refresh.vue";
import {Toast} from "_vant@2.12.53@vant";
import env from "../../../env";

export default {
  components: {
    Refresh
  },
  data() {
    return {
      active: 0,
      cpage: 1,
      switch: true,
      navTab: 0,
      historyIndex: 1,
      showContent: false,
      index: 0,
      arrlist: [],
      arrDate: [],
      navPut: -1,
      isChoose: 1,
      showPut: false,
      visitNoTip: {},
      visitTip: {},
      val: '',
      signItem: '',
      visitNum: 0,
      payNum: 0,
      msg: '',
      timeBean: {},
      yearMonthPicker: 1,

      info: {},
      signList: [],
      jobList: [],
      clockList: [],
      visitList: [],
      moneyList: [],
      bankList: [],
      payList: [],
      stayList: [],
      orderList: [],
      quitList: [],

      itemclock: {}
    }
  },
  async mounted() {

    console.log('test');

    // setEnterPath('/');

    // 判断绑定
    if (!checkBind(this.$router)) {
      return false;
    }

    // 判断初始化
    if (!checkLoadWxConfig(this.$router)) {
      return false;
    }

    this.getMonth();

    // 获取客户资料
    this.getShow();
  },
  methods: {

    getUserId() {

      let userInfo = getUserInfo();
      let qy_out_user_id = userInfo.qy_out_user_id;

      return qy_out_user_id;
    },

    //获取月份
    async getMonth() {
      let qy_out_user_id = this.getUserId();
      let res = await getYearMonth({
        qy_out_user_id: qy_out_user_id
      });

      if (res.code == 200) {
        let arr = [];
        if (res.data.length != 0) {
          res.data.forEach((v, i) => {
            let obj = {};
            v.month = v.month < 10 ? '0' + v.month : v.month
            obj.time = v.year + '-' + v.month;
            obj.id = v.id;
            arr.push(obj)
          })
          this.arrDate = arr;
          this.payroll_id = arr[0].id;
        }
      }
    },
    async getShow() {
      let qy_out_user_id = this.getUserId();
      let res = await getKehuDetail({
        qy_out_user_id: qy_out_user_id,
        type: this.navTab,
        visit_type: this.historyIndex,
        date: this.startTime,
        payroll_id: this.payroll_id
      });

      if (res.code == 200) {
        if (this.navTab == 0) {
          this.info = res.data.user_detail;
        } else if (this.navTab == 1) {
          this.signList = res.data.list.data;
        } else if (this.navTab == 2) {
          this.jobList = res.data.list.data;
        } else if (this.navTab == 3) {
          this.clockList = res.data.list.data;
        } else if (this.navTab == 4) {
          this.visitList = res.data.list.data;
          this.visitNum = res.data.num;
          this.visitTip = res.data.tips;
          this.visitNoTip = res.data.no_tips;
        } else if (this.navTab == 5) {
          this.moneyList = res.data.list.data;
        } else if (this.navTab == 6) {
          this.bankList = res.data.list;
        } else if (this.navTab == 7) {
          this.payList = res.data.list.data;
          this.payNum = res.data.num;
        } else if (this.navTab == 8) {
          this.stayList = res.data.list.data;
        } else if (this.navTab == 9) {
          this.orderList = res.data.list.data;
        } else if (this.navTab == 10) {
          let addTime = res.data.list.other;
          let arrlist = [];
          for (let item in addTime) {
            let obj = {}
            obj.name = item;
            obj.text = addTime[item];
            arrlist.push(obj)
          }
          this.arrlist = arrlist;

        } else if (this.navTab == 11) {
          this.quitList = res.data.list.data;
        }
      }
    },

    //tab切换
    handleTab(navTab) {
      this.navTab = navTab;
      this.cpage = 1;
      this.switch = true;
      console.log(this.arrDate.length);
      if (navTab == 10) {
        if (this.arrDate.length != 0) {
          this.getShow();
        }
      } else {
        this.getShow();
      }
    },

    //报名记录收单查看
    handleSee(content) {

      if (this.navTab == 3) {
        this.itemclock = content;
      } else {
        this.signItem = content;
      }

      console.log(content);

      console.log(this.navTab);
      console.log(this.itemclock.off_work_address);

      this.showContent = true;
    },

    // 查看报名详情
    handleDetail(id) {
      this.$router.push({
        path: `/home/detail/${id}`
      });
    },

    //历史记录切换
    handleHistory(content) {
      this.historyIndex = content;
      this.cpage = 1;
      this.switch = true;
      this.getShow();
    },

    //回访弹窗选择
    handleSPut(index, item) {

      console.log(index, item);

      this.navPut = index;
      this.val = item;
    },

    //请选择
    handleChoose() {
      this.isChoose = 2;
      this.showPut = true;
      this.navPut = -1;
      this.val = '';
    },

    //回访
    handleVisit() {
      this.isChoose = 1;
      this.showPut = true;
      this.navPut = -1;
      this.val = '';
    },

    //关闭
    onClose() {
      this.showContent = false;
      this.showPut = false;
    },

    //回访确定
    handlePSubmit() {
      if (this.isChoose == 2) {
        if (!this.val) {
          Toast.fail('请先选择标签');
          return
        }
      } else {
        if (!this.val) {
          Toast.fail('请先输入回访或选择标签');
          return
        }
      }
      this.getVisit()
    },
    //回访
    async getVisit() {
      let info = this.info;
      let user_id = ''
      if (info) {
        user_id = this.info.user_id
      }

      let res = await setVisit({
        user_id: user_id || this.user_id,
        msg: this.val,
        public_id: this.id,
        store_type: this.store_type,
        type: this.isChoose == 2 ? 1 : ''
      });

      if (res.code == 200) {
        Toast.success('回访成功');
        this.showPut = false;
        setTimeout(() => {
          this.getShow()
        }, 1000);
      }
    },

    //异常记录 提交反馈
    handleFeed() {

    },
  }
}
</script>

<style>
body {
  background: #f5f5f5;
}

.memeberRecord_index {
  /*position: sticky;*/
  /*top: 0;*/
  z-index: 100;
  padding-top: 10px;
  background-color: #FFFFFF;
}

.public_visit_bottom {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.van-tabs__line {
  background-color: #389EEE !important;
}

.public_visit {
  padding: 0 0 20px;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 100;
}



.public_index {
  padding: 10px;
}


.van-tab {
 /*font-size: 26px;*/
  line-height: 76px;
}

.van-tabs--line .van-tabs__wrap {
  height: 80px;
}

.showPut {
  width: 600px;
  padding: 30px 0;
}

.showPut_title {
  border-bottom: 1px solid #F6F6F6;
  padding: 10px 30px 30px;
  max-height: 220px;
  overflow-y: auto;
}

.showPut_title>div {
  display: inline-block;
  background: #F4F4F4;
  /*font-size: 28px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding: 10px 15px;
  margin: 0 20px 20px 0;
  border-radius: 10px;
}

.showPut_title_active {
  background: #DFF2FF !important;
  color: #1F97EE !important;
}

.van-popup {
  border-radius: 12px;
}

.showPut textarea {
  width: 90%;
  height: 150px;
  background-color: #F6F6F6;
  /*font-size: 26px;*/
  padding: 10px;
  box-sizing: border-box;
  margin: 30px auto;
}

.showPut_content {
  padding: 0 30px;
}

.showPut_button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.showPut_button div {
  color: #ffffff;
  /*font-size: 26px;*/
  background-color: #389EEE;
  padding: 10px 10px;
  width: 170px;
  border-radius: 10px;
  text-align: center;
}

.showPut_button div:first-child {
  background-color: #96CCF6;
}



.myMember_source_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px 10px 15px 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tab_top {
  border-top: 1px solid #F5F5F5;
  margin-bottom: 10px;
  justify-content: start;
}
.tab_top>div{
  flex: none!important;
  margin-right: 20px;
}

.tabActive {
  color: #1F97EE !important;
  position: relative;
}

.tabActive::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 12px;
  background: #1F97EE;
}

.myMember_source_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  /* width: 16%; */
  flex: 1;
  text-align: center;
}

/* 基本信息 ----------------------------------------*/
.navTab_one {
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
}

.name_icon {
  background: linear-gradient(#3FA6F0, #7BC0F4);
  color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.navTab_one_list {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 28px;*/
  border-bottom: 1px solid #F5F5F5;
  padding: 10px;
}

.navTab_one_list>div:first-child {
  width: 100px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 28px;*/
}

.navTab_one_list>div:last-child {
  flex: 1;
}

/* 基本信息----------------------- */

/* 报名记录-------------------------- */
.navTab_two {
  padding: 10px 10px;
  background: #ffffff;
  margin-top: 10px;
}

.navTab_two_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
}

.navTab_two_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_two_list {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #F5F5F5;
  padding: 10px 0;
}

.navTab_two_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

/* 报名记录----------------------------------------------------------- */

/* 在离职记录-------------------------------------------------------------- */
.navTab_three_list {
  background: #ffffff;
  padding: 10px;
  line-height: 58px;
  margin-bottom: 10px;
}

.navTab_three_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
 /*font-size: 26px;*/
}

.navTab_three_list_flex>div:first-child {
  width: 180px;
}

.navTab_three_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
 /*font-size: 26px;*/
}

/* 在离职记录-------------------------------------------------------------- */


/* 打卡记录-------------------------------------------------------------- */
.navTab_four {
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
}

.navTab_four_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
  width: 100%;
}
.navTab_four_tab div {
  /*border: 1px solid red;*/
}

.navTab_four_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_four_list {
  display: flex;
  /*align-items: center;*/
  /*justify-content: space-between;*/
  padding: 10px 0;
  border-bottom: 1px solid #F5F5F5;
  margin: 0;
  /*border: 1px solid blue;*/
  text-align: center;
}
.navTab_four_list .empty_cell {
  display: block;
  visibility: hidden;
}

.navTab_four_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 21%;
}

.navTab_four_list_day span {
  display: inline-block;
  background: #E9F5FF;
  padding: 2px;
  border-radius: 3px;
  font-family: PingFang SC-Regular, PingFang SC;
  color:#1F97EE;
  font-weight: 400;
}
.navTab_four_list_day_night span{
  color:#999999;
}

.navTab_four_list_status span{
  background: #E9F5FF;
  padding: 2px;
  border-radius: 3px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1F97EE;
}
.navTab_four_list_status_error span{
  color:#FF4E3A;
}

/* 打卡记录------------------------------------------------------- */

/* 回访记录------------------------------------------------------- */
.navTab_five_tab {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  justify-content: space-around;
}

.navTab_five_tab>div {
  background: #F5F5F5;
  /*font-size: 30px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 20px;
}

.historyActive {
  background: #E9F5FF !important;
  color: #1F97EE !important;
}

.public_visit_flex {
  display: flex;
  align-items: center;
  /*font-size: 30px;*/
  height: 60px;
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
}

.public_visit_div {
  text-align: center;
  /*font-size: 30px;*/
  background: #DCF0FF;
  color: #389EEE;
  padding: 8px 8px;
  border-radius: 20px;
  width: 70px;
  border: 1px solid #97CFF7;
}

.public_index_main {
  background: #ffffff;
  margin-bottom: 10px;
  padding: 10px;
}

.public_index_main_list {
  display: flex;
  /*font-size: 28px;*/
  line-height: 30px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:first-child {
  width: 100px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.public_index_main_list>div:last-child {
  flex: 1;
}

.public_index_main_list_time {
  display: flex;
  /*font-size: 28px;*/
  /*line-height: 48px;*/
  color: #999999;
}

.public_index_main_list_time>div:first-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 26px;*/
}

.public_index_main_list_width>div:first-child {
  margin-right: 20px;
}

/* 回访记录------------------------------------------------ */

/* 发薪记录------------------------------------------------ */
.navTab_six {
  margin-top: 10px;
}

.navTab_six_list {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
 /*font-size: 26px;*/
  padding: 10px;
  background: #ffffff;
  line-height: 30px;
  margin-bottom: 10px;
}

.navTab_six_list_flex>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
 /*font-size: 26px;*/
}

.navTab_six_list_flex {
  display: flex;
}

.navTab_six_list_flex>div:first-child {
  width: 100px;
}

.navTab_six_list_flex_title {
  flex: 1;
}

.navTab_six_list_flex_son {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
 /*font-size: 26px;*/
}

.navTab_six_list_flex_son>div:last-child {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
 /*font-size: 26px;*/
  flex: 1;
  line-height: 16px;
}

/* 发薪记录------------------------------------------------ */


/* 工资条------------------------------------------------ */

.paySilp {
  width: 100%;
  position: relative;
}

.paySilp_name {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  white-space: nowrap;
}

.paySilp_price {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}

.paySilp_price div:first-child {
  font-size: 56px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}

.paySilp_price div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  opacity: .9;
  margin-top: 10px;
}

.paySilp_basic div:last-child {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.month {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
}

.paySilp_day {
  width: 90%;
  margin: 10px auto;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 10px 10px;
  box-sizing: border-box;
}

.paySilp_day_title {
  /*font-size: 22px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #7F7F7F;
  position: relative;
  width: 92px;
  margin-bottom: 10px;
}

.paySilp_day_title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 26px;
  height: 4px;
  background: #389EEE;
  border-radius: 4px;
}

.paySilp_day_list {
  display: flex;
  align-items: center;
  /*font-size: 22px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #090909;
  padding: 10px 0;
  border-bottom: 1px solid #F2F2F2;
}

.paySilp_day_list:last-child {
  border-bottom: 0;
}

.paySilp_day_list div:last-child {
  /*font-size: 20px;*/
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-left: auto;
}

.payImg {
  text-align: center;
  font-size: 32px;
  background: #E0C169;
  height: 100%;
  padding-top: 150px;
  box-sizing: border-box;
  color: #FFFFFF;
}

.payImg image {
  width: 250px;
  height: 230px;
  display: block;
  margin: 0px auto 10px;
}

page,
.div {
  height: 100%;
}

.picker {
  font-size: 30px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picker_play {
  transform: rotate(90deg);
  margin-left: 5px;
}

/* 工资条------------------------------------------------ */



/* 银行卡信息------------------------------------------------ */
.navTab_seven {
  padding-top: 10px;
}
.navTab_seven_list {
  background: #ffffff;
  padding: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}

.navTab_seven_list_flex {
  display: flex;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
 /*font-size: 26px;*/
}

.navTab_seven_list_flex>div:first-child {
  width: 100px;
}

.navTab_seven_list_flex>div:last-child {
  flex: 1;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
 /*font-size: 26px;*/
}

/* 银行卡信息-------------------------------------------------------- */

/* 异常记录-------------------------------------------------------- */
.navTab_eight_flex {
  display: flex;
  align-items: center;
  /*font-size: 28px;*/
  background: #ffffff;
  padding: 10px 10px;
  margin-top: 10px;
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
}

.navTab_eight_div {
  text-align: center;
  /*font-size: 28px;*/
  background: #E9F5FF;
  color: #389EEE;
  padding: 10px 10px;
  border-radius: 20px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navTab_eight_div .van-icon-plus {
  margin-right: 10px;
}

.navTab_eight_title {
  display: flex;
  align-items: center;
}

.navTab_eight_status {
  margin-left: auto;
  color: #FF913D;
}
.navTab_eight_status_processed {
  color: #1F97EE;
}

.navTab_eight_over {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

/* 异常记录-------------------------------------------------------- */

/* 住宿记录-------------------------------------------------------- */

.navTab_nine {
  padding: 10px;
  background: #ffffff;
  margin-top: 10px;
}

.navTab_nine_tab {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #F5F5F5;
  margin-bottom: 10px;
}

.navTab_nine_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

.navTab_nine_list {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_nine_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
  width: 20%;
  text-align: center;
}

/* 住宿记录-------------------------------------------------------- */

/* 订单查看记录-------------------------------------------------------- */
.navTab_ten {
  margin-top: 10px;
}

.navTab_ten_time {
  background: #ffffff;
  padding: 10px;
}

.data_week_btn_time {
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 0px;
}

.navTab_ten_main {
  background: #ffffff;
  padding: 10px;
  margin-top: 10px;
}

.navTab_ten_tab {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  padding-bottom: 10px;
}

.navTab_ten_tab>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /*font-size: 24px;*/
}

.navTab_ten_list {
  display: flex;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #F5F5F5;
}

.navTab_ten_list>div {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  /*font-size: 24px;*/
}

.navTab_ten_time .index {
  background: #ffffff;
}

/* 订单查看记录-------------------------------------------------------- */
.memeberPhone {
  position: fixed;
  bottom: 300px;
  right: 30px;
  width: 100px;
  height: 100px;
  z-index: 100;
}

.memeberPhone image {
  width: 100%;
  height: 100%;
}

.showContent {
  padding: 10px;
  width: 400px;
  height: 300px;
}

.showContent_signItem {
  height: 500px;
  overflow-y: auto;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 30px;
}

.showContent_time {
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  font-size: 16px;
  line-height: 30px;
}

.showContent_time>div>text {
  color: #333333;
}

.navTab_five_tab_choose {
  text-align: center;
  /*font-size: 30px;*/
  background: #ffece9;
  color: #FF4E3A;
  padding: 8px 8px;
  border-radius: 20px;
  margin-left: auto;
  width: 70px;
  border: 1px solid #FF4E3A;
  margin-right: 20px;
}

.showPut_no_title {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #f5f5f5;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.showPut_no_tip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /*font-size: 24px;*/
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF4E3A;
  padding: 15px;
}

.showPut_no_tip>div {
  flex: 1;
}

.appletsContractFile{
  border: 1px solid #EFEFEF;
  border-radius: 2px;
  width: 50px;
  height: 50px;
  margin: auto 2px;
}

.line{
  border-top: 1px dotted #CCC;
  margin: 20px;
}
.moreNull {
  text-align: center;
  /*font-size: 30px;*/
  color: #999999;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
